@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
}

code {
  font-family: 'Plus Jakarta Sans';
}

/* Your custom styles */
@layer components {
  .your-existing-class {
    @apply bg-blue-500 text-white;
    /* other custom properties */
  }
}