.login-banner {
    border-radius: 0px 0px 24px 24px;
    background: linear-gradient(90deg, #070A0A 0%, #355555 100%);
    color: white;
}

.shareAndBack{
    background: transparent;
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.Btn{
    padding:8px;
    border-radius: 50%;
background: rgba(0, 0, 0, 0.75);
backdrop-filter: blur(1.5px);
margin:8px;
}