.customDots{
    border-radius: 100px;
background: rgba(201, 201, 201, 0.55);
backdrop-filter: blur(1.5px);
width: 14px;
height: 2px;
}

.control-dots{
    display: flex;
    justify-content: center;
    margin:16px 0px !important;
    gap:4px;
}

.carousel-status{
    display: none;
}

.gymName{
    color:  #05070B;
font-family: "Plus Jakarta Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 27px */
letter-spacing: 0.3px;
margin-bottom: 6px;
}

.price{
    color: #05070B;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
margin-left: 4px;
}


.slashed-price{
    color: #B1AFB0;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style:normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
text-decoration: line-through;
}

.line{
width: 100%;
height: 1px;
background: #E9E9E9;
}

.baseTxt{
    color: #05070B;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
text-transform: capitalize;
display: flex;
align-items: center;
}

.baseTxt1{
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.locWrp{
    padding:12px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.locWrpCol{
    flex-direction: column;
    flex-wrap: wrap;
}

.gymPageheading{
    color:  #05070B;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
padding:16px 0px

}

.gPageDesc{
    color:  #828081;
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.21px;
    padding-bottom: 8px;
}

.bookBtn{
border-radius: 0px 0px 8px 8px;
background: #05070B;
color:#fff;
display: flex;
padding: 12px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
margin:0px auto 8px auto;
align-self: stretch;

color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
width:85%;
text-align: center;

}

.bookBtn2{
    border-radius: 8px 8px 8px 8px;
background: #05070B;
color:#fff;
display: flex;
padding: 12px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
margin:0px auto 8px auto;
align-self: stretch;

color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
width:85%;
text-align: center;

}

.bookBtnWrap{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.55) 19%, #FFF 100%);
    /* position: absolute; */
    position: fixed;

    bottom:0px;
    width:100%;
}

.activities{
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    max-height: 90px;
    margin-bottom:8px;
    overflow-y: hidden;

}

.amenities{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;    
}

/* .amenities:nth-of-type(odd){

} */

.amenitiesItem{
    width: 50%;
    margin-bottom:12px;

    /* margin-right: auto; */
}

.amenety{
    color: var(--Text-Primary, #05070B);
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.carouselWrap{
    max-height: 200px;
    width: 100%;
    overflow-y: hidden !important;
}

.gymWrap{
    overflow: auto;
    height: 100%;
    padding-bottom: 60px;
}

.shareAndBack{
    background: transparent;
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.Btn{
    padding:8px;
    border-radius: 50%;
background: rgba(0, 0, 0, 0.75);
backdrop-filter: blur(1.5px);
margin:8px;
}

.gRating{
    color: var(--Text-Primary, #05070B);
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
}


.gIcon{
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translate(-2px, 2px);
}

.timeWrap{
    display: flex;
    width:78vw;
    justify-content: space-between;
    align-items: center;
}
.timeDay{
    color: var(--Text-Primary, #05070B);
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
text-transform: capitalize;
width: 38px;
display: inline-block;

}
.timeHours{
color: var(--Text-Primary, #05070B);
text-overflow: ellipsis;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;

}

.timeWrapper{
    display: flex;
    gap: 8px;
}
.info{
    margin-left:auto
}

.ant-card-body {
    padding:12px 24px !important
}

.player >video{
    height: auto !important;
}

.discountLine1{
    bottom:53px;
    background-color: #1AAC6D;
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    width: 85%;
    /* 16.2px */
    letter-spacing: 0.18px;
    margin:-1px auto;
    border-radius: 8px 8px 0px 0px;
}

.muteIcon{
    position: absolute;
    z-index: 1000;
    top: 144px;
    right: 16px;
    height: 30px;
    width: 30px;
    font-size:20px;

}
