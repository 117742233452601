.home-banner {
    background: linear-gradient(to right, #9B205D, #7ACBF8);    
    border-radius: 10px;  
    color: white;      
    margin-bottom: 16px;
}

.sectionHeading{
color: #212121;
text-align: right;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 1.92px;
text-transform: uppercase;
padding:16px 0px;
width: 100%;
white-space: nowrap;
margin-right:16px;
}

.homeWrapper{
    width: 100%;
    height:192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
}

.loginWrapper{
    height: 192px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    margin:24px;
}

.zenfit{
    color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 43.2px */
letter-spacing: 4.16px;
}

.x{
    color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 32px;
font-style: normal;
font-weight: 800;
line-height: 135%;
letter-spacing: 4.16px;


}
.head2{
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 80%;
    margin:6px 0px

}


.head3{
    color: #FFF0A4;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 12.299px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:12px

}

.cardWrapper{
    border-radius: 12px;
        border: 1px solid #F1F1F1;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom:22px;
box-shadow: 0px 4px 16px 0px rgba(5, 7, 11, 0.08);
}

.activityImg1{
    width: 100%;
}

.activityImg1Img{
    width: 100%;
    height: 150px;
    aspect-ratio: 25/36;
    border-radius: 12px 12px 0px 0px;
    object-fit: cover;
}

.activityDetailWrapper{
    padding:16px 0px 16px 0px;
    width: 100%;
    
    
}
.activityDetailWrapper2{
    padding:16px 0px 8px 0px;
    width: 100%;
}
.activityDetail{
    padding:0px 20px;
}
.name{
    color:  #05070B;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
margin-bottom:4px;
display: flex;
justify-content: space-between;
max-height: 24px;
}

.name > span{
    white-space:break-spaces;
    max-width:70%;
}

.nameInc{
    color:  #05070B;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%; /* 21.6px */
    letter-spacing: 0.24px;
    margin-bottom:4px;
    display: flex;
    justify-content: space-between;
    min-height:22px;  
    max-height: 44px;
}

.nameInc > span{
    white-space:break-spaces;
    max-width:70%;
}

.nameNoheight > span{
    white-space:break-spaces;
    max-width:70%;
}

.nameNoheight{
    color:  #05070B;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%; /* 21.6px */
    letter-spacing: 0.24px;
    margin-bottom:4px;
    display: flex;
    justify-content: space-between;   
}

.activity{
color:  #828081;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
}

.separator {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #838383 0%, #FFF 100%);
    margin: 8px 0px;
}

.location {
    color: #05070B;
    font-family: "Plus Jakarta Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 13.5px */
    letter-spacing: 2.6px;
    text-transform: uppercase;
    display: flex;
    gap: 8px;
    align-items: center;
}

.discount {
    border-radius: 8px;
    background: rgba(5, 194, 112, 0.09);
    margin: 12px 8px 0px 8px;

    >div {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

    }

}


.dCard {
    display: flex;
    padding: 12px 4px 8px 4px;
    flex-direction: column;
    align-items: center;
    border-radius: 0px 4px 4px 4px;
    background: linear-gradient(180deg, #C0C0C0 0%, #EEE 26.25%, #F1F1F1 89.41%);
    width:44px;
    height:38px;
    transform: translateY(-28px);
}
.dCard ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 52px;
        width: 0;
    height: 0;
    border-right: 8px solid #212121;
    border-top: 8px solid transparent;
}
.dPrice{
    color: #040700;
text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;

}

.sPrice{
    color: #000;
text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 10.8px */
letter-spacing: 0.64px;
}
.slash{
    text-decoration-line:line-through
}

.dTxt{
color:  #05070B;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
}

.sWrap{
    position: absolute;
    transform:translateY(14px)
}

.specialWrap{
    border-radius: 0px 4px 4px 0px;
background: linear-gradient(270deg, #3A6161 0%, #0E1616 100%);
box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
padding:2px 8px;
display:flex;
flex-direction: row-reverse;
align-items: center;
}
.specialTxt{
    /* font-family: "Montserrat"; */
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 15px */
letter-spacing: 1px;
    background: linear-gradient(90deg, #FFEB81 0%, #FFF7CC 60%, #FFEB81 92.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-left:2px;
}
.specialIcon{

}

.trendingBox{
    border-radius: 12px;
background: linear-gradient(90deg, #000 -2.08%, #253B3B 103.61%);
width: 157px;
height: 90px;
}

.trending{
    background: linear-gradient(90deg, #FFF0A4 0%, #FFF8D4 66.5%, #F5D37C 98%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.zBox{
    color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: normal;
padding-left:16px;
}

.trendingWrapper{
    padding:16px 16px 4px 16px;
    display: flex;
}

.loginName{
    color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 32.4px */
letter-spacing: 0.36px;
}

.loginHead1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    margin-bottom:40px;

}

.loginHead2{
    color: #FFF0A4;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
margin-bottom:4px
}
.loginHead3{
    color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
max-width: 90%;
}

.bookings{
    border-radius: 12px;
border: 1px solid #FFF;
background: #05070B;
display: flex;
padding: 8px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
}

.shareAndBack{
    background: transparent;
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.Btn{
    padding:8px;
    border-radius: 50%;
background: rgba(0, 0, 0, 0.75);
backdrop-filter: blur(1.5px);
margin:8px;
}

.obHead{
    display: flex;
width: 100%;
padding: 12px 8px;
justify-content: center;
align-items: center;
gap: 8px;
background: #212121;
color: #FFF;
text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 3.36px;
text-transform: uppercase;
}

.obWrap{
    background: #fff;
    overflow: hidden;
}

.obImgWrap{
    /* background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%); */
    position: absolute;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    height: 100vh;
}

.obImg{
    width: 100%;
    /* height: 100vh; */
}

.exploreBtn{
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    filter: blur(0px);
    backdrop-filter: blur(0px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 0.01%, rgba(0, 0, 0, 0.59) 22.5%, #000 100%);    padding:24px 0px;
    align-items: center;
    z-index:10000;
}

.obBtn{
border-radius: 12px;
border: 1px solid #E1E1E1;
display: flex;
width: 80vw;
padding: 16px 8px;
justify-content: center;
align-items: center;
gap: 8px;
opacity: 0.8;
background: linear-gradient(180deg, #212121 10%, #C0E4DB 100%);
box-shadow: 2px 2px 14px 0px #FFF inset, 8px 8px 55px 0px rgba(123, 244, 252, 0.55);
color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 27px */
text-transform: capitalize;
cursor: pointer;
}

.obacitivities{
    display: flex;
    justify-content: center;
    background-color: transparent;
    position: absolute;
    top: 32px;
    z-index:1000;
}

.obActWrap{
    display: flex;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
}

.obActWrap >div>span{
font-family: "Plus Jakarta Sans";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 48.6px */
letter-spacing: 0.54px;
opacity: 0.4;
margin-right:8px;

}

.magic{
}

.obActWrap >div .bold{
    color: #3A6362 !important;
     font-size: 36px;
     font-weight: 700;
     line-height: 48.60px;
     letter-spacing: 0.54px;
     word-wrap: break-word;
     opacity: 1;
}

.fh1{
color: white;
 font-size: 24px;
 font-family: Plus Jakarta Sans;
 font-weight: 400;
 line-height: 32.40px;
 letter-spacing: 0.36px;
 word-wrap: break-word

}
.fh2{
color: rgba(255, 255, 255, 0.65);
 font-size: 16px;
 font-family: Plus Jakarta Sans;
 font-weight: 400;
 line-height: 21.60px;
 letter-spacing: 0.24px;
 word-wrap: break-word

}
