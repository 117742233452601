.dateTileWrap {
    position: absolute;
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 16.2px */
    letter-spacing: 0.18px;
    top: 32px;
    width:100%

}

.detailWrap{
    display:flex;
    flex-direction: column;
    padding:0px 16px;
    margin-bottom:24px;


}

.gymNames{
    color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 27px */
letter-spacing: 0.3px;
margin:8px 0px;
}

.locationName{
    color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
text-transform: capitalize;
display: flex;
align-items: flex-start;
gap:4px;

}
.backBtn{
    color:'#fff';
}

.activityImg{
    height: 250px;
    aspect-ratio: 25/36;
    width: 100%;
}

.activityHeading{
color: #212121;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 27px */
letter-spacing: 0.3px;
margin-top:12px;
}

.activityDate{
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(33, 33, 33, 0.75);
    margin:4px 0px
}

.remainingSlots{
    color: #C15700;
    font-family: "Plus Jakarta Sans";
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 27px */
    letter-spacing: 0.3px;
    margin-top:4px;
    margin-bottom: 32px;
}

.dot{
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #212121;
    margin:0px 4px;
}
.activityLoc{
    color: var(--Text-Primary, #05070B);
font-family: "Plus Jakarta Sans";
font-size: 12px;
text-align: center;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
text-transform: capitalize;
margin-top:8px;
margin-bottom:0px;

}

.sectionActHeading{
    color:  #05070B;
text-align: right;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 16.2px */
letter-spacing: 1.92px;
text-transform: uppercase;
display: flex;
align-items: center;
white-space:nowrap;

justify-content: center;
width: 100%;
}

.sectionActItems{
    color: #696969;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
letter-spacing: 0.21px;
}

.shareAndBack{
    background: transparent;
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.Btn{
    padding:8px;
    border-radius: 50%;
background: rgba(0, 0, 0, 0.75);
backdrop-filter: blur(1.5px);
margin:8px;
}

.locIcon{
    position: absolute;
transform: translate(-20px,0px);
}

.activityContainer{
    padding: 0px 16px;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 130px;
}

.stickyWrap{
    position: sticky;
    top: 0px;
    z-index: 10;
}

.checkWrapper{
    padding:24px;
}
.backBtn2{
    text-align: start;
}

.checkoutDetail{
    padding:8px;
    display: flex;
    gap:16px;
    align-items: flex-start;
}

.actImg >img{
    border-radius: 12px;    
}

.actDetail{

}

.actName{
    color: #212121;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
}

.actGym , .actLoc{
    color: rgba(33, 33, 33, 0.75);
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
}

.actTime{
    color: rgba(33, 33, 33, 0.75);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
letter-spacing: 0.21px;
}

.actLine{
    height: 1px;
    background: #E9E9E9;
    margin:12px 0px;
}

.priceHead{
    color: #212121;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
}

.checkDsc{
    color: var(--Text-Primary, #05070B);
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
letter-spacing: 0.21px;
}

.checkAmt{
    color: var(--Text-Primary, #05070B);
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 18.9px */
letter-spacing: 0.21px;
}

.flexy{
    display: flex;
    justify-content: space-between;
    padding:2px 0px
}

.saveDsc{
    color: #3EAA63;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
}
.saveAmt{
    color: var(--Text-Secondary, #828081);
font-family: "Plus Jakarta Sans";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 13.5px */
letter-spacing: 0.15px;
text-decoration-line: line-through;
}

.guestCount{

}

.guestIcon{
    display: flex;
    align-items: center;
    color: var(--Text-Primary, #05070B);
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
text-transform: capitalize;
}

.counter{
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.disabled{
    pointer-events: none;
    border:1px solid #828081 !important;
    opacity: 0.5;

}

.disabledSoldOut{
    pointer-events: none;
    opacity: 0.5;
}

.counter >span>svg{
    border:1px solid #000;
    border-radius: 50%;
}

.offer{
    border-radius: 12px;
    padding: 4px 12px;
    font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 16.2px */
display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0px;
}

.offerGray{
    background: #F1F1F1;
    color:#05070B;
}
.offerGreen{
    color: #1AAC6D;
    background: #EDF8F4;  
}
.guestArea{
    padding:16px 0px;
}

.discountLine {
    position: absolute;
    bottom:69px;
    background-color: #1AAC6D;
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    width: 100%;
    /* 16.2px */
    letter-spacing: 0.18px;
}

.discountLineAlmost {
    position: absolute;
    bottom:130px;
    background-color: #1AAC6D;
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    width: 100%;
    /* 16.2px */
    letter-spacing: 0.18px;
}

.discountedAmountWrap{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    color: var(--Text-Primary, #05070B);
font-family: "Plus Jakarta Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 27px */
letter-spacing: 0.3px;
}

.discountedAmount{
    color:#828081;
font-size: 12px !important;
font-style: normal;
font-weight: 400 !important;
line-height: 135%;
letter-spacing: 0.18px;
text-decoration-line: line-through;
    
}